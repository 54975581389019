<template>
    <div class="home">
        <h1>Page not found</h1>
    </div>
</template>

<script>
export default {
    name: 'NotFound',
    components: {}
}
</script>
<style lang="scss" scoped>
h1 {

    text-align: center;
    margin-top: 100px;
    font-size: 50px;
    color: #00000044;
}
</style>